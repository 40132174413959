/* line 13, scss/80-themes/AffinityDefault/70-modules/gtsdisclaimer/module.scss */
.m-disclaimer {
	font-size: 0.92em;
	line-height: 1.4em;
	font-family: var(--font-family-text);
	font-weight: 500;
	color: rgb(var(--color-global-secondary));
	padding-bottom: 20px;
	text-transform: initial;
	font-display: swap;
}

@media only screen and (max-width: 47.999em) {
	/* line 13, scss/80-themes/AffinityDefault/70-modules/gtsdisclaimer/module.scss */
	.m-disclaimer {
		font-size: 1.0718em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 13, scss/80-themes/AffinityDefault/70-modules/gtsdisclaimer/module.scss */
	.m-disclaimer {
		font-size: 0.92em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 13, scss/80-themes/AffinityDefault/70-modules/gtsdisclaimer/module.scss */
	.m-disclaimer {
		font-size: 0.92em;
	}
}

/* line 23, scss/80-themes/AffinityDefault/70-modules/gtsdisclaimer/module.scss */
.m-disclaimer a {
	color: rgb(var(--color-global-secondary));
}

/* line 26, scss/80-themes/AffinityDefault/70-modules/gtsdisclaimer/module.scss */
.m-disclaimer a:hover {
	color: rgb(var(--color-global-secondary));
}

/* line 32, scss/80-themes/AffinityDefault/70-modules/gtsdisclaimer/module.scss */
.disclaimer__link {
	color: rgb(var(--color-global-secondary));
}

/* line 35, scss/80-themes/AffinityDefault/70-modules/gtsdisclaimer/module.scss */
.disclaimer__link:hover {
	color: rgb(var(--color-global-secondary));
}

/* line 42, scss/80-themes/AffinityDefault/70-modules/gtsdisclaimer/module.scss */
.paw-footer .m-disclaimer {
	width: 50%;
	padding-bottom: 0;
	text-align: right;
	font-size: "";
	color: rgb(var(--color-global-secondary));
	display: inline-block;
}

/* line 50, scss/80-themes/AffinityDefault/70-modules/gtsdisclaimer/module.scss */
.paw-footer .m-disclaimer a {
	color: rgb(var(--color-global-secondary));
}

/*# sourceMappingURL=../../../../../true */